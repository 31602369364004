import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Naming convention`}</h2>
    <p>{`Since margin utilities have many variations and will be used in many places, we use a shorthand naming convention to help keep class names succinct.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shorthand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`m`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`t`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`top`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`r`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`b`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`l`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`horizontal, left & right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`vertical, top & bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Uniform margins`}</h2>
    <p>{`Use uniform spacing utilities to apply equal margin to all sides of an element. These utilities can change or override default margins, and can be used with a spacing scale from 0-6.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--uniform'
    }]} height="150" mdxType="StorybookEmbed" />
    <h2>{`Directional margins`}</h2>
    <p>{`Use directional utilities to apply margin to an individual side, or the X and Y axis of an element. Directional utilities can change or override default margins, and can be used with a spacing scale of 0-6.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--directional'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Extended vertical margins`}</h2>
    <p>{`The extended scale starts from spacer `}<inlineCode parentName="p">{`7`}</inlineCode>{` up to `}<inlineCode parentName="p">{`12`}</inlineCode>{`. `}<strong parentName="p">{`Note`}</strong>{`: Only the y-axis (`}<inlineCode parentName="p">{`mt`}</inlineCode>{`, `}<inlineCode parentName="p">{`mb`}</inlineCode>{` and `}<inlineCode parentName="p">{`my`}</inlineCode>{`) and its responsive variants are supported.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--directional-extended'
    }]} height="200" mdxType="StorybookEmbed" />
    <h2>{`Center elements`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`mx-auto`}</inlineCode>{`to center block elements with a set width.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--auto'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`We also provide directional margin auto. `}<inlineCode parentName="p">{`mt-auto, mr-auto, mb-auto, ml-auto`}</inlineCode></p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--auto-directional'
    }]} height="250" mdxType="StorybookEmbed" />
    <h2>{`Reset margins`}</h2>
    <p>{`Reset margins built into typography elements or other components with `}<inlineCode parentName="p">{`m-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`mt-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`mr-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`mb-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`ml-0`}</inlineCode>{`, `}<inlineCode parentName="p">{`mx-0`}</inlineCode>{`, and `}<inlineCode parentName="p">{`my-0`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--reset'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Responsive margins`}</h2>
    <p>{`All margin utilities can be adjusted per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{` using the following formula: `}<inlineCode parentName="p">{`m[direction]-[breakpoint]-[spacer]`}</inlineCode>{`. Each responsive style is applied to the specified breakpoint and up.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Negative margins`}</h2>
    <p>{`You can add negative margins to the top, right, bottom, or left of an item by adding a negative margin utility. The formula for this is: `}<inlineCode parentName="p">{`m[direction]-n[spacer]`}</inlineCode>{`, where `}<inlineCode parentName="p">{`spacer`}</inlineCode>{` runs from `}<inlineCode parentName="p">{`1`}</inlineCode>{` to `}<inlineCode parentName="p">{`6`}</inlineCode>{`. This also works responsively, with the following formula: `}<inlineCode parentName="p">{`m[direction]-[breakpoint]-n[spacer]`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--negative'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Extended negative margins`}</h2>
    <p>{`You can use the extended spacing scale for `}<inlineCode parentName="p">{`top`}</inlineCode>{` and `}<inlineCode parentName="p">{`bottom`}</inlineCode>{` margins, ranging from `}<inlineCode parentName="p">{`1`}</inlineCode>{` to `}<inlineCode parentName="p">{`12`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-margin--negative-extended'
    }]} height="150" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      